<template>
  <v-autocomplete
    v-model="localValue"
    :class="['input']"
    color="accent"
    dense
    auto-select-first
    :multiple="multiple"
    :label="input.displayName"
    :filled="input.filled"
    item-color="accent"
    item-text="display"
    item-value="value"
    :items="input.options"
    placeholder=" "
    :rounded="input.rounded"
    :disabled="disabled"
    :rules="input.rules"
    :error-messages="errorMessages"
  />
</template>

<script>
export default {
  name: "DropDown",

  props: {
    input: {
      type: Object,
      required: false,
    },
    disabled: Boolean,
    multiple: Boolean,
    errorMessages: String,
  },

  data: () => ({}),

  computed: {
    localValue: {
      get() {
        return this.input.value
      },
      set(newValue) {
        this.$emit("update:value", newValue)
      },
    },
  },

  methods: {},
}
</script>

<style lang="scss" scoped>
.input {
  margin-bottom: -30px;
}
</style>
